import { Preferences } from "@capacitor/preferences";
import { useModalState } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { signedContractorAgreement } from "@src/app/onboardingStripe/api";
import { AgreementContainer } from "@src/app/onboardingStripe/components/agreementContainer";
import { VerifyOtpDialog } from "@src/appV2/Auth/LoginSignUp/Dialogs";
import { AuthMode } from "@src/appV2/Auth/LoginSignUp/libs";
import { StepUpAuthConfirmationText } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/StepUpAuthConfirmationText";
import { StepUpAuthRequestOtpButton } from "@src/appV2/Auth/LoginSignUp/StepUpAuth/StepUpAuthRequestOtpButton";
import {
  APP_V2_APP_EVENTS,
  APP_V2_USER_EVENTS,
  AppBarHeader,
  PageWithHeader,
  useToast,
} from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { APP_BAR_HEIGHT } from "@src/appV2/lib/AppBarHeader/AppBarHeader";
import { Worker } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { LocalStorage } from "@store/session";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * TODO: This page is soon to be moved to appV2 directory. It wasn't added there yet because
 * It relies on some old logic for signing the agreement, but it will be refactored as a part of
 * IC Agreement Improvements project soon
 */
export function SignNewAgreementPage() {
  const history = useHistory();

  const worker = useDefinedWorker();

  const { phone, userId, name } = worker;

  const modalState = useModalState();
  const updateWorkerCache = useUpdateWorkerCache();

  const [isAuthorized, setIsAuthorized] = useState(false);

  const { showErrorToast } = useToast();

  /**
   * This is ugly function copy-pasted from OnboardToStripe. It will be refactored when
   * we move signing the agreement to a new endpoint so for now I didn't put any effort
   * trying to improve it and possibly breaking something in a code that is soon to be removed
   */
  const { mutateAsync: onAcceptAgreement, isLoading: isSigning } = useMutation({
    mutationFn: async (agreementVersion: string) => {
      try {
        const updatedAgent = await signedContractorAgreement(userId, agreementVersion, name);

        logEvent(APP_V2_USER_EVENTS.SIGN_NEW_AGREEMENT_STARTED, {
          workerId: userId,
          email: worker?.email,
          signature: worker?.name,
          agreementVersion,
        });

        const { signedContractorAgreements } = updatedAgent;

        updateWorkerCache({ signedContractorAgreements });

        const { value } = await Preferences.get({ key: LocalStorage.AGENT });
        if (value) {
          const agent = JSON.parse(value) as Worker;
          agent.signedContractorAgreements = signedContractorAgreements;
          await Preferences.set({
            key: LocalStorage.AGENT,
            value: JSON.stringify(agent),
          });
        }

        logEvent(APP_V2_APP_EVENTS.SIGN_NEW_AGREEMENT_SUCCESS, {
          agreementVersion,
        });

        history.push("/");
      } catch (error) {
        showErrorToast(
          "Sorry we are experiencing technical problems. Please contact support, or try again later."
        );
        logError(APP_V2_APP_EVENTS.SIGN_NEW_AGREEMENT_FAILURE, {
          error,
          metadata: {
            userId,
          },
        });
      }
    },
  });

  return (
    <>
      <PageWithHeader appBarHeader={<AppBarHeader title="Sign New Agreement" />}>
        {isAuthorized ? (
          <Box sx={{ marginTop: APP_BAR_HEIGHT, position: "absolute", inset: 0 }}>
            <AgreementContainer
              disableBackButton
              onAcceptAgreement={(agreementVersion) => onAcceptAgreement(agreementVersion)}
              isLoading={isSigning}
            />
          </Box>
        ) : (
          <Stack direction="column" gap={2} justifyContent="space-between" height="100%">
            <StepUpAuthConfirmationText phoneNumber={phone} />
            <StepUpAuthRequestOtpButton
              eventName={APP_V2_USER_EVENTS.SIGN_NEW_AGREEMENT_SEND_OTP}
              phoneNumber={phone}
              onSuccess={() => {
                modalState.openModal();
              }}
            />
          </Stack>
        )}
      </PageWithHeader>

      <VerifyOtpDialog
        modalState={modalState}
        phoneNumber={phone}
        authMode={AuthMode.AD_HOC}
        onSuccess={async () => {
          modalState.closeModal();
          setIsAuthorized(true);
        }}
      />
    </>
  );
}
