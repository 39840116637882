import "./styles.scss";
import { ContactSupportLinks } from "@src/appV2/support/types";

import { IcAgreementHelpText } from "../../../../appV2/IcAgreement/IcAgreementHelpText";

export interface ReadAgreementErrorProps {
  userId: string;
  contactSupportLinks: ContactSupportLinks;
}

export function ReadAgreementError(props: ReadAgreementErrorProps) {
  const { contactSupportLinks, userId } = props;

  return (
    <div className="ic-agreement-error-message-container">
      Please read the full agreement and scroll to the bottom before signing.
      <br />
      <br />
      <IcAgreementHelpText contactSupportLinks={contactSupportLinks} userId={userId} />
    </div>
  );
}
