import "./styles.scss";
import { Preferences } from "@capacitor/preferences";
import { AgreementContainer } from "@src/app/onboardingStripe/components/agreementContainer";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { Worker } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "@src/appV2/Worker/useUpdateWorkerCache";
import { USER_EVENTS } from "@src/constants";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { createPaymentServiceAccount, signedContractorAgreement } from "./api";
import { OnBoardingComponentProps } from "../onboarding/components/model";
import { LocalStorage, reloadAgentProfile } from "../store/session";

export const OnboardToStripe: FC<OnBoardingComponentProps> = ({
  nextStagePath,
  disableBackButton,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const worker = useDefinedWorker();
  const history = useHistory<{ redirectTo: "" }>();
  const { showErrorToast } = useToast();
  const updateWorkerCache = useUpdateWorkerCache();

  useEffect(() => {
    const hasWorkerName = !!worker.name?.trim();
    setLoading(!hasWorkerName);
    if (hasWorkerName) {
      return;
    }
    dispatch(reloadAgentProfile());
  }, [dispatch, worker.name]);

  const handleAccountCreate = useCallback(async () => {
    const splitName = worker.name?.split(" ") ?? [];
    let firstName = "";
    let lastName = "";
    if (splitName.length > 0) {
      firstName = splitName[0] ?? "";
      lastName = splitName[splitName?.length - 1] ?? "";
    }

    const hcpAddress = {
      line1: `${worker.address?.streetNumber || ""} ${worker.address?.streetName ?? ""}`,
      city: worker.address?.city,
      state: worker.address?.stateCode,
      postalCode: worker.address?.postalCode,
    };
    return await createPaymentServiceAccount({
      agentId: worker.userId,
      email: worker.email as string,
      msa: worker.address?.metropolitanStatisticalArea,
      individual: {
        firstName: worker.firstName || firstName,
        lastName: worker.lastName || lastName,
        phone: worker.phone,
        birthday: worker.dob,
        address: hcpAddress,
      },
    });
  }, [worker]);

  const handleAgreementAcceptance = useCallback(
    async (agreementVersion) => {
      try {
        setLoading(true);

        const updatedAgent = await signedContractorAgreement(
          worker.userId as string,
          agreementVersion,
          worker.name
        );
        logEvent(USER_EVENTS.SIGNED_CONTRACT, {
          hcpID: worker.userId as string,
          email: worker.email,
          signature: worker.name,
          agreementVersion,
        });

        const { signedContractorAgreements } = updatedAgent;

        if (!worker.paymentAccountInfo) {
          try {
            const paymentAccountInfo = await handleAccountCreate();
            updateWorkerCache({
              signedContractorAgreements,
              paymentAccountInfo,
            });
          } catch (error) {
            const errorMessage = (error.response as any)?.body?.message;
            // Stripe may indicate error passed client-side validation, like invalid address, postal code, etc.
            const isStripeError = error.status === 400 && errorMessage;
            const defaultMessage =
              "You have accepted the Independent Contractor Agreement successfully. However, we are having a technical issue accessing or creating your Stripe account.  Please contact Customer Support.";
            const stripeErrorMessage = `You have accepted the Independent Contractor Agreement successfully. However, your stripe account wasn't created. Error: ${errorMessage}. Please contact Customer Support.`;
            showErrorToast(isStripeError ? stripeErrorMessage : defaultMessage);
            logError(APP_V2_APP_EVENTS.STRIPE_ONBOARDING_ERROR, {
              error,
              metadata: {
                message: "Error occurred setting up stripe account on IC Agreement signing",
                userId: worker.userId,
              },
            });
            return;
          }
        }
        updateWorkerCache({
          signedContractorAgreements,
        });

        const { value } = await Preferences.get({ key: LocalStorage.AGENT });
        if (value) {
          const agent = JSON.parse(value) as Worker;
          agent.signedContractorAgreements = signedContractorAgreements;
          await Preferences.set({
            key: LocalStorage.AGENT,
            value: JSON.stringify(agent),
          });
        }

        if (nextStagePath) {
          history.push(nextStagePath);
          return;
        }
        const { redirectTo } = history?.location?.state || {};
        if (redirectTo) {
          history.replace(redirectTo);
        }
      } catch (error) {
        showErrorToast(
          "Sorry we are experiencing technical problems. Please contact support, or try again later."
        );
        logError(APP_V2_APP_EVENTS.STRIPE_ONBOARDING_ERROR, {
          error,
          metadata: {
            message: "Error occurred signing IC Agreement",
            userId: worker.userId,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [worker, handleAccountCreate, history, nextStagePath, showErrorToast, updateWorkerCache]
  );

  return (
    <div className="cbhAppV1">
      <AgreementContainer
        onAcceptAgreement={handleAgreementAcceptance}
        isLoading={loading}
        disableBackButton={disableBackButton}
      />
    </div>
  );
};
