import "../styles.scss";
import { BackButtonEvent } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { environmentConfig } from "@src/appV2/environment";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { PaymentServiceAccountStatus } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { openInAppBrowser } from "@src/lib/deprecatedCode";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { USER_EVENTS } from "../../../constants/userEvents";
import { TabRouterPath } from "../../routing/constant/tabRoute";
import { generateDynamicLink, generatePaymentServiceOnboardingLink } from "../api";
import { DISMISSED_STRIPE_POPUP } from "../constants";
import { useShouldBeOnboarded } from "../hooks";

export function SetUpStripe() {
  const { showErrorToast } = useToast();
  const location = useLocation();
  const history = useHistory<{ showBackButton: boolean }>();
  const [loading, setLoading] = useState(false);
  const worker = useDefinedWorker();
  const shouldBeOnboarded = useShouldBeOnboarded();

  const isSecondTimeOnboarding = useMemo(() => {
    const status = worker.paymentAccountInfo?.status;
    return (
      status === PaymentServiceAccountStatus.INSTANT_PAYOUT_PENDING ||
      status === PaymentServiceAccountStatus.STANDARD_PAYOUT_PENDING ||
      status === PaymentServiceAccountStatus.ACCOUNT_NEEDS_UPDATE ||
      worker.paymentAccountInfo?.showOnboard
    );
  }, [worker]);

  useEffect(() => {
    try {
      const preventNavigation = (ev: BackButtonEvent) => {
        ev.detail.register(100, (nextHandler) => {
          if (location.pathname !== TabRouterPath.PAYMENT_SERVICE_ONBOARDING) {
            nextHandler();
          }
        });
      };
      document.addEventListener("ionBackButton", preventNavigation as any);
      return () => document.removeEventListener("ionBackButton", preventNavigation as any);
    } catch (err) {
      console.log(err);
    }
  }, [location]);

  useEffect(() => {
    if (!shouldBeOnboarded) {
      history.replace(TabRouterPath.OPEN_SHIFTS);
    }
  }, [history, shouldBeOnboarded]);

  const handleOnboarding = useCallback(async () => {
    try {
      setLoading(true);

      const [returnUrl, refreshUrl] = await Promise.all([
        generateDynamicLink(
          `${environmentConfig.REACT_APP_WEB_APP_URL}${TabRouterPath.SUCCESS_ONBOARDING}`
        ),
        generateDynamicLink(`${environmentConfig.REACT_APP_WEB_APP_URL}${location.pathname}`),
      ]);

      const onboardingLink = await generatePaymentServiceOnboardingLink(worker.userId, {
        returnUrl,
        refreshUrl,
      });

      setLoading(false);
      openInAppBrowser(onboardingLink, "_system");
    } catch (err) {
      setLoading(false);
      showErrorToast("Sorry we are experiencing technical problems. Please contact support. ");
    }
  }, [worker.userId, location.pathname, showErrorToast]);

  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_STRIPE_SET_UP, {
      hcpID: worker.userId,
      name: worker.name,
    });
  }, [worker]);

  const handleNotNow = () => {
    logEvent(USER_EVENTS.DISMISSED_STRIPE_PROMPT, {
      hcpID: worker.userId,
    });
    localStorage.setItem(DISMISSED_STRIPE_POPUP, "true");
    history.replace(TabRouterPath.OPEN_SHIFTS);
  };

  const firstParagraph = useMemo(() => {
    if (isSecondTimeOnboarding) {
      return `Our payments partner still needs some information in order to pay you.`;
    } else if (worker.isFirstSession) {
      return `We work with Stripe to send you instant payments directly to your account.`;
    } else {
      return `We have a new Instant Payments partner! With Stripe, we’ll
      send instant payments
      directly to your account.`;
    }
  }, [worker, isSecondTimeOnboarding]);

  if (!shouldBeOnboarded) {
    return null;
  }

  return (
    <IonPage>
      <IonContent>
        <IonHeader no-border>
          <IonToolbar>
            {history?.location?.state?.showBackButton && (
              <IonButtons slot="start">
                <IonBackButton text="" defaultHref="/home" mode="ios" />
              </IonButtons>
            )}
            <IonTitle size="small" className="header">
              Working with Clipboard Health
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonText
            color="title"
            class="secondary-heading ion-margin-bottom"
            style={{ fontSize: "16px" }}
          >
            <div>Set Up Debit Card With Stripe For Instant Payments</div>
          </IonText>
          <img
            style={{ width: "45%", maxWidth: "300px", margin: "0.5rem 2.5rem" }}
            src="assets/logo/bunny.png"
            alt="Clipboard logo"
          />
          <p className="space" style={{ fontSize: "13px" }}>
            {firstParagraph}
          </p>
          <p className="space" style={{ fontSize: "13px" }}>
            <IonText color="primary" style={{ fontWeight: "bold" }}>
              All debit cards and most bank account types are eligible for instant payouts!
            </IonText>
          </p>
          <div className="container" style={{ justifyContent: "center" }}>
            <IonButton
              fill="outline"
              className="footer"
              onClick={() => {
                logEvent(USER_EVENTS.STARTED_STRIPE_SET_UP, {
                  hcpID: worker.userId,
                });
                handleOnboarding();
              }}
            >
              {loading ? (
                <IonSpinner color="light" name="crescent"></IonSpinner>
              ) : (
                "Finish Set Up To Get Paid"
              )}
            </IonButton>
            <IonText className="not-now" onClick={handleNotNow}>
              Not Now
            </IonText>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
