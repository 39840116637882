import { PaymentServiceAccountStatus } from "@src/appV2/Worker/api/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { ShouldBeOnboardedStatuses } from "./model";

export function useShouldBeOnboarded() {
  const worker = useDefinedWorker();

  const isAgentPendingInPaymentService =
    worker.canBeOnboardedInStripe &&
    ShouldBeOnboardedStatuses.includes(
      worker.paymentAccountInfo?.status as PaymentServiceAccountStatus
    );

  return !!isAgentPendingInPaymentService;
}
